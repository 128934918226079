import { getFishTypesData } from '@app/api/fishtypes.api';
import { IErrorData } from '@app/interfaces/apiError';
import { IFishType } from '@app/interfaces/fishtype';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

export interface IState {
  loading: boolean;
  fishtypes: IFishType[];
}

export const initialState = {
  loading: false,
  fishtypes: [],
} as IState;

const slice = createSlice({
  name: 'fishtypes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFishtypes.pending, (state: IState) => ({
        ...state,
        loading: true,
      }))
      .addCase(fetchFishtypes.fulfilled, (state: IState, { payload }: { payload: { fishtypes: IFishType[] } }) => ({
        ...state,
        loading: false,
        fishtypes: payload.fishtypes,
      }))
      .addCase(fetchFishtypes.rejected, (state: IState) => ({
        ...state,
        loading: false,
        fishtypes: initialState.fishtypes,
      }));
  },
});

export const fetchFishtypes = createAsyncThunk('fetchFishtypes', async (_: undefined, { rejectWithValue }) => {
  try {
    const response = await getFishTypesData();

    // TODO: remove mocking
    const fishtypes = (response.data as IFishType[]).map((f) => ({
      ...f,
      abbreviation: f.abbreviation ? f.abbreviation : 'MOCK' + f.id,
    }));

    return { fishtypes };
  } catch (error) {
    const err = error as AxiosError;
    return rejectWithValue(err.response?.data as IErrorData);
  }
});

export const fishtypes = slice.reducer;
