import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useAppSelector } from '@app/hooks/reduxHooks';
import {
  breedingJournalAclSelector,
  inspectionsAclSelector,
  observationsAclSelector,
  paperStatisticsAclSelector,
} from '@app/store/slices/userSlice';
import * as S from './SiderMenu.styles';
import { sidebarNavigation, SidebarNavigationItem } from '../sidebarNavigation';

interface SiderContentProps {
  setCollapsed: (isCollapsed: boolean) => void;
}

const sidebarNavFlat = sidebarNavigation.reduce(
  (result: SidebarNavigationItem[], current) =>
    result.concat(current.children && current.children.length > 0 ? current.children : current),
  [],
);

const SiderMenu: React.FC<SiderContentProps> = ({ setCollapsed }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const inspectionsFeature = useAppSelector(inspectionsAclSelector);
  const observationsFeature = useAppSelector(observationsAclSelector);
  const breedingJournalFeature = useAppSelector(breedingJournalAclSelector);
  const paperStatisticsFeature = useAppSelector(paperStatisticsAclSelector);

  const currentMenuItem = sidebarNavFlat.find(({ url }) => url === location.pathname);
  const defaultSelectedKeys = currentMenuItem ? [currentMenuItem.key] : [];

  const openedSubmenu = sidebarNavigation.find(({ children }) =>
    children?.some(({ url }) => url === location.pathname),
  );
  const defaultOpenKeys = openedSubmenu ? [openedSubmenu.key] : [];

  return (
    <S.Menu
      mode="inline"
      defaultSelectedKeys={defaultSelectedKeys}
      defaultOpenKeys={defaultOpenKeys}
      onClick={() => setCollapsed(true)}
      items={sidebarNavigation
        .filter((navItem) => {
          if (navItem.key === 'inspections') {
            return (
              inspectionsFeature?.visible && inspectionsFeature.views.find((view) => view.name === 'tableView')?.visible
            );
          }
          if (navItem.key === 'observations') {
            return (
              observationsFeature?.visible &&
              observationsFeature.views.find((view) => view.name === 'tableView')?.visible
            );
          }
          if (navItem.key === 'breedingJournal') {
            return breedingJournalFeature?.visible;
          }
          if (navItem.key === 'import-paper-statistics') {
            return paperStatisticsFeature?.visible;
          }
          return true;
        })
        .map((nav) => {
          const isSubMenu = nav.children?.length;

          return {
            key: nav.key,
            title: t(nav.title),
            label: isSubMenu ? t(nav.title) : <Link to={nav.url || ''}>{t(nav.title)}</Link>,
            icon: nav.icon,
            children:
              isSubMenu &&
              nav.children &&
              nav.children.map((childNav) => ({
                key: childNav.key,
                label: <Link to={childNav.url || ''}>{t(childNav.title)}</Link>,
                title: t(childNav.title),
              })),
          };
        })}
    />
  );
};

export default SiderMenu;
