import { createSlice, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
// import crashlytics from '@react-native-firebase/crashlytics';
import { AxiosError } from 'axios';
import { getUserRequest } from '@app/api/user';
import { IErrorData } from '../../interfaces/apiError';
import { IUser } from '@app/interfaces/user';
import { logout } from './token';
import { defaultAcl } from './defaultAcl';

export interface IState {
  loading: boolean;
  userData: IUser;
}

export const initialState = {
  loading: false,
  userData: {},
} as IState;

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // ========= Fetch user start =========
      .addCase(fetchUser.pending, (state: IState) => ({
        ...state,
        loading: true,
      }))
      .addCase(
        fetchUser.fulfilled,
        (state: IState, { payload }: { payload: { userData: IUser; lastUserId?: number } }) => ({
          ...state,
          loading: false,
          userData: {
            ...payload.userData,
            personal_number: payload.userData.personal_number ? payload.userData.personal_number : '001', // TODO: FIX this after api changes
          },
          lastUserId: payload.userData.id,
        }),
      )
      .addCase(fetchUser.rejected, (state: IState) => ({
        ...state,
        loading: false,
        userData: initialState.userData,
      }))
      // ========= Fetch user end =========

      // ========= Logout =========
      .addCase(logout.fulfilled, (state: IState) => ({
        ...state,
        loading: initialState.loading,
        userData: initialState.userData,
      }));
  },
});

export const fetchUser = createAsyncThunk('fetchUser', async (_: undefined, { rejectWithValue }) => {
  // crashlytics().log('Get api user data request start.');
  try {
    const response = await getUserRequest();
    const userData = response.data as IUser;

    // crashlytics().log('User signed in.');
    // await Promise.all([crashlytics().setUserId(`${userData.id}`)]);

    if (!userData.acl) {
      userData.acl = defaultAcl;
    }

    return { userData };
  } catch (error) {
    const err = error as AxiosError;
    // crashlytics().log("Error while trying to get user's data.");
    // crashlytics().recordError(error as Error);
    return rejectWithValue(err.response?.data as IErrorData);
  }
});

// export const { } = slice.actions;

export const user = slice.reducer;

export const userIdSelector = createSelector(
  (state: { user: IState }) => state.user.userData,
  (userData) => userData.id,
);

export const aclSelector = createSelector(
  (state: { user: IState }) => state.user.userData,
  (userData) => userData.acl,
);

export const organizationEntitiesSelector = createSelector(
  (state: { user: IState }) => state.user.userData,
  (userData) => userData.organizationEntities,
);

export const dashboardAclSelector = createSelector(aclSelector, (acl) =>
  acl?.modules.find((module) => module.name === 'dashboard'),
);

export const dashboardHomeAclSelector = createSelector(dashboardAclSelector, (dashboardAcl) =>
  dashboardAcl?.features.find((feature) => feature.name === 'dashboardHomeView'),
);

export const observationsAclSelector = createSelector(dashboardAclSelector, (dashboardAcl) =>
  dashboardAcl?.features.find((feature) => feature.name === 'observations'),
);

export const inspectionsAclSelector = createSelector(dashboardAclSelector, (dashboardAcl) =>
  dashboardAcl?.features.find((feature) => feature.name === 'inspections'),
);

export const breedingJournalAclSelector = createSelector(dashboardAclSelector, (dashboardAcl) =>
  dashboardAcl?.features.find((feature) => feature.name === 'breedingJournal'),
);

export const paperStatisticsAclSelector = createSelector(dashboardAclSelector, (dashboardAcl) =>
  dashboardAcl?.features.find((feature) => feature.name === 'paperStatistics'),
);
