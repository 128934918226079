import React from 'react';
import { ProfileOverlay } from '../ProfileOverlay/ProfileOverlay';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BasePopover } from '@app/components/common/BasePopover/BasePopover';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { useResponsive } from '@app/hooks/useResponsive';
import { BaseAvatar } from '@app/components/common/BaseAvatar/BaseAvatar';
import * as S from './ProfileDropdown.styles';

import { ReactComponent as ProfileDefault } from '@app/assets/icons/profile.svg';
import { themeObject } from '@app/styles/themes/themeVariables';

const size = 40;

export const ProfileDropdown: React.FC = () => {
  const { isTablet } = useResponsive();

  const theme = useAppSelector((state) => state.theme.theme);
  const { userData } = useAppSelector((state) => state.user);

  return userData != null ? (
    <BasePopover content={<ProfileOverlay />} trigger="click">
      <S.ProfileDropdownHeader as={BaseRow} gutter={[10, 10]} align="middle">
        <BaseCol>
          {'imgUrl' in userData ? (
            <BaseAvatar src={(userData.imgUrl as string) ?? ''} alt="User" shape="circle" size={size} />
          ) : (
            <ProfileDefault width={size} height={size} fill={themeObject[theme as keyof typeof themeObject].textMain} />
          )}
        </BaseCol>
        {isTablet && (
          <BaseCol>
            <span>{`${userData.firstname} ${userData.lastname ? userData.lastname[0] : ''}`}</span>
          </BaseCol>
        )}
      </S.ProfileDropdownHeader>
    </BasePopover>
  ) : null;
};
