import { IFishType } from '@app/interfaces/fishtype';
import { api } from './http.api';

const resourcesPathBase = '/web/admin/fishtypes';

export const getFishTypesData = async (): Promise<{ error: boolean; data?: IFishType[] }> => {
  try {
    const { data } = await api.get(resourcesPathBase);

    return { error: false, data };
  } catch (error) {
    return { error: true, data: undefined };
  }
};
