import nightModeReducer from '@app/store/slices/nightModeSlice';
import themeReducer from '@app/store/slices/themeSlice';
import pwaReducer from '@app/store/slices/pwaSlice';
import { user as userReducer } from '@app/store/slices/userSlice';
import { token as tokenReducer } from '@app/store/slices/token';
import { config as configReducer } from '@app/store/slices/config';
import { forgotPassword as forgotPasswordReducer } from '@app/store/slices/forgotPassword';
import { locale as localeReducer } from '@app/store/slices/locale';
import { waterBodies as waterBodiesReducer } from '@app/store/slices/waterbodies';
import { breedingJournal as breedingJournalReducer } from '@app/store/slices/breedingJournal';
import { fishtypes as fishtypesReducer } from '@app/store/slices/fishtypes';
import { homeFilters as homeFiltersReducer } from '@app/store/slices/homeFilters';
import { inspectionsFilters as inspectionsFiltersReducer } from '@app/store/slices/inspectionsFilters';
import { breedingJournalFilters as breedingJournalFiltersReducer } from '@app/store/slices/breedingJournalFilters';
import { observationsFilters as observationsFiltersReducer } from '@app/store/slices/observationsFilters';

export default {
  breedingJournal: breedingJournalReducer,
  user: userReducer,
  nightMode: nightModeReducer,
  theme: themeReducer,
  pwa: pwaReducer,
  token: tokenReducer,
  config: configReducer,
  forgotPassword: forgotPasswordReducer,
  locale: localeReducer,
  waterBodies: waterBodiesReducer,
  fishtypes: fishtypesReducer,
  homeFilters: homeFiltersReducer,
  inspectionsFilters: inspectionsFiltersReducer,
  breedingJournalFilters: breedingJournalFiltersReducer,
  observationsFilters: observationsFiltersReducer,
};
